import React from 'react'
import { NakedInputWrap, StripeElementWrap } from 'components/forms/styles'

const StripeElementWrapper = ({ children }) => (
  <NakedInputWrap>
    <StripeElementWrap>{children}</StripeElementWrap>
  </NakedInputWrap>
)

export default StripeElementWrapper
