import React from 'react'
import siteConfig from 'config/site'
import Layout from 'layouts/default'
import Wrap from 'components/wrap'
import PayForm from 'components/forms/pay-form'
import StripeProvider from 'components/forms/stripe-provider'
import Countdown from 'components/countdown'
import Title from 'components/title'

const PayPage = () => (
  <Layout path="/pay">
    {process.env.GATSBY_EVILNEON_CART_ENABLED === 'true' ? (
      <Wrap>
        <StripeProvider>
          <PayForm />
        </StripeProvider>
      </Wrap>
    ) : (
      <Countdown date={siteConfig.launchDate}>
        <Title is="h2">{siteConfig.launchTitle}</Title>
      </Countdown>
    )}
  </Layout>
)

export default PayPage
