import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { rem, flex, is, position, transparentize } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Loader, { LoaderWrap } from 'components/loader'
import BounceLetters, { BounceLettersWrap } from 'components/bounce-letters'

const { colors, fonts, speeds } = theme
const { white, iron, black, blue } = colors

const Wrapper = styled.div`
  ${flex('column', 'center', 'center')}
  ${position('absolute', 0)}
  animation: fadeIn ${speeds.slow}ms ease forwards;
  background: ${transparentize(black, 0.2)};
  min-height: 100%;
  user-select: none;
  z-index: 3;

  ${is('fixed')`
    position: fixed;
    top: ${rem(48)};
  `}

  ${LoaderWrap} {
    margin-bottom: ${rem(80)};
  }

  ${BounceLettersWrap} {
    border-bottom: ${rem(1)} solid ${iron};
    color: ${white};
    font-family: ${fonts.primary};
    font-size: ${rem(18)};
    font-weight: 200;
    margin: ${rem(16)} auto;
    padding: ${rem(8)} ${rem(32)};
    text-transform: uppercase;

    ${media.small`
      font-size: ${rem(24)};
      padding: ${rem(16)} ${rem(48)};
    `}
  }
`

export const BusyMessage = ({ children, fixed = false }) => (
  <Wrapper fixed={fixed}>
    <BounceLetters>{children}</BounceLetters>
    <Loader size={72} fill={blue} />
  </Wrapper>
)

const BusyMessagePortal = ({ global: globalStore, children }) => {
  const { body } = global.document || {}

  useEffect(() => {
    globalStore.setIsBusy(true)
    return () => globalStore.setIsBusy(false)
  })

  if (!body) return null
  return createPortal(<BusyMessage fixed>{children}</BusyMessage>, body)
}

export default inject('global')(observer(BusyMessagePortal))
