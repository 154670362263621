import React from 'react'
import styled from 'styled-components'
import { rem, flex, position, is, transparentize } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { colors, speeds } = theme
const { white, pearl, silver, black, blue } = colors

const ToggleInputWrap = styled.div`
  ${flex('row', 'center', 'flex-start')}
`

const UI = styled.div`
  ${flex('row', 'center', 'center')}
  background: ${white};
  border-radius: ${rem(16)};
  cursor: pointer;
  height: ${rem(32)};
  overflow: hidden;
  position: relative;
  transition: box-shadow ${speeds.quick}ms linear;
  user-select: none;
  width: ${rem(64)};

  :hover {
    box-shadow:
      0 0 0 ${rem(1)} ${pearl},
      0 0 0 ${rem(3)} ${silver};
  }

  :before {
    ${position('absolute', 0)}
    border-radius: ${rem(16)};
    box-shadow: inset 0 ${rem(8)} ${rem(8)} ${transparentize(black, 0.8)};
    content: '';
    height: ${rem(32)};
  }

  :after {
    background: ${white};
    box-shadow: 0 ${rem(2)} ${rem(3)} ${transparentize(black, 0.7)};
    content: '';
    height: ${rem(26)};
    border-radius: ${rem(16)};
    left: ${rem(3)};
    position: absolute;
    top: ${rem(3)};
    width: ${rem(26)};
  }

  ${is('side', 'left')`
    :before {
      background: ${blue};
    }
    :after {
      transform: translateX(calc(100% + ${rem(6)}));
    }
  `}

  ${is('side', 'right')`
    :before {
      background: ${silver};
    }
    :after {
      transform: translateX(0);
    }
  `}
`

const ToggleInput = ({ selected, onChange }) => (
  <ToggleInputWrap onClick={onChange}>
    <UI side={selected ? 'left' : 'right'} />
  </ToggleInputWrap>
)

export default ToggleInput
