import theme from 'lib/styles/theme'

const { colors, fonts } = theme

export default {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400',
    },
  ],
  style: {
    base: {
      color: colors.tar,
      fontFamily: fonts.secondary,
      fontSize: 'inherit',
      fontWeight: 400,
      '::placeholder': {
        color: colors.silver,
      },
    },
  },
}
