import React from 'react'
import { inject } from 'mobx-react'
import isEmpty from 'lodash.isempty'
import styled from 'styled-components'
import { rem, value, flex, is, minWidth } from 'styled-tidy'
import { formatPrice } from 'lib/common/helpers'
import theme from 'lib/styles/theme'
import { Title } from 'components/forms/styles'
import X from 'components/icons/x'
import Price from 'components/price'
import ArtSize, { ArtSizeWrapper } from 'components/art-size'

const { white, pearl, smoke, ember } = theme.colors

const CartSummaryWrapper = styled.div`
  padding: ${rem(8)} 0;
  user-select: none;
  width: 100%;
`

const CartSummaryItemRow = styled.div`
  ${flex('row', 'center', 'space-between')}
  background: ${pearl};
  border-top: ${rem(2)} solid ${smoke};

  :last-child {
    > div {
      background: ${white};
      border-right: ${rem(2)} solid ${pearl};

      :last-child {
        font-weight: 600;
        font-size: ${rem(18)};
      }
    }
  }
`

const CartSummaryItemGroup = styled.div`
  ${flex('row', 'center', 'space-between')}
  flex-wrap: wrap;
  min-height: ${rem(48)};
  padding: ${rem(8)};
  text-align: left;

  :first-child {
    background: ${smoke};
    border-right: 0;
    justify-content: center;
    padding: 0;
    width: ${rem(48)};
  }

  :last-child {
    min-width: ${rem(96)};
    justify-content: flex-end;
    text-align: right;
  }

  img {
    border: ${rem(4)} solid transparent;
    min-width: ${rem(48)};
    max-height: ${rem(48)};
    max-width: ${rem(48)};
    object-fit: contain;
  }

  dfn,
  strong {
    display: block;
    font-size: ${rem(13)};
  }
  dfn {
    font-style: normal;
    font-weight: 200;
  }
  strong {
    display: block;
    flex-grow: 1;
    margin-top: ${rem(4)};
    text-align: left;
    width: 100%;
  }

  ${minWidth(520)`
    strong {
      display: inline;
      margin-top: 0;
      text-align: right;
      width: auto;
    }
  `}

  ${ArtSizeWrapper} {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
  }

  ${is('padding')`
    padding: ${value('padding')};
  `}

  ${is('flex')`
    border: ${rem(2)} solid ${smoke};
    border-bottom: 0;
    border-top: 0;
    font-size: ${rem(14)};
    flex: 1;

    :first-child {
      border-left: 0;
    }
  `}

  ${is('align', 'right')`
    justify-content: flex-end !important;

    dfn,
    strong {
      padding: ${rem(8)};
      text-align: right;
      width: 100%;
    }
    dfn {
      margin-top: ${rem(-4)};
      padding-top: 0;
    }
  `}
`

const CartSummary = ({ cart: cartStore, justSubtotal = false }) => {
  const { items, order, itemsTotal } = cartStore
  const shippingMethod = order.shippingMethods.find(
    method => method.id === order.selectedShippingMethod,
  )
  const shippingTotal = shippingMethod ? shippingMethod.amount : 0
  const hasTaxes = !!order.taxes.length
  const taxTotal = hasTaxes
    ? order.taxes.reduce((total, tax) => ({
        amount: total.amount + tax.amount,
      })).amount
    : 0
  const total = itemsTotal + shippingTotal + taxTotal

  return (
    <>
      <Title>Order Summary</Title>
      <CartSummaryWrapper>
        {items.map((item, key) => {
          const isPrint = !isEmpty(item.print)
          const { size, price } = item[isPrint ? 'print' : 'product']

          return (
            <CartSummaryItemRow key={`cart-summary-row-item-${key}`}>
              <CartSummaryItemGroup padding={4}>
                <img src={item.product.images[0]} alt={item.name} />
              </CartSummaryItemGroup>
              <CartSummaryItemGroup flex={1}>
                {item.product.name}
                {isPrint ? ' ⋅ Print ⋅ ' : ' ⋅ '}
                <ArtSize size={size} color={ember} />
                {item.quantity > 1 && (
                  <strong>
                    {formatPrice(price, false)}
                    <X size={8} fill={ember} />
                    {item.quantity}
                  </strong>
                )}
              </CartSummaryItemGroup>
              <CartSummaryItemGroup>
                <Price
                  cents={
                    (isPrint ? item.print.price : item.product.price) *
                    item.quantity
                  }
                />
              </CartSummaryItemGroup>
            </CartSummaryItemRow>
          )
        })}
        <CartSummaryItemRow>
          <CartSummaryItemGroup flex={1} align="right">
            <strong>Item Total</strong>
          </CartSummaryItemGroup>
          <CartSummaryItemGroup>
            <Price cents={itemsTotal} />
          </CartSummaryItemGroup>
        </CartSummaryItemRow>
        {!justSubtotal &&
          hasTaxes &&
          order.taxes.map((tax, key) => (
            <CartSummaryItemRow key={`cart-summary-tax-${key}`}>
              <CartSummaryItemGroup flex={1} align="right">
                <strong>{tax.description}</strong>
              </CartSummaryItemGroup>
              <CartSummaryItemGroup>
                <Price cents={tax.amount} />
              </CartSummaryItemGroup>
            </CartSummaryItemRow>
          ))}
        {!justSubtotal && !!shippingMethod && (
          <CartSummaryItemRow>
            <CartSummaryItemGroup flex={1} align="right">
              <strong>{shippingMethod.description}</strong>
            </CartSummaryItemGroup>
            <CartSummaryItemGroup>
              <Price cents={shippingTotal} />
            </CartSummaryItemGroup>
          </CartSummaryItemRow>
        )}
        {!justSubtotal && (
          <CartSummaryItemRow>
            <CartSummaryItemGroup flex={1} align="right">
              <strong>Total</strong>
            </CartSummaryItemGroup>
            <CartSummaryItemGroup>
              <Price cents={total} />
            </CartSummaryItemGroup>
          </CartSummaryItemRow>
        )}
      </CartSummaryWrapper>
    </>
  )
}

export default inject('cart')(CartSummary)
