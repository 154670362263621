import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { navigate } from 'gatsby'
import { Formik, Field } from 'formik'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import checkoutConfig from 'config/checkout'
import stripeConfig from 'config/stripe'
import { formatPrice } from 'lib/common/helpers'
import AddressCard from 'components/address-card'
import BusyMessage from 'components/busy-message'
import CartSummary from 'components/cart-summary'
import ToggleInput from 'components/toggle-input'
import StripeElementWrapper from 'components/forms/stripe-element-wrapper'
import {
  Form,
  Title,
  BillingTitle,
  Input,
  NakedInputWrap,
  Error,
  RowSplit,
  RowSplitItem,
  SubmitButton,
  ToggleWrap,
} from 'components/forms/styles'

const PayForm = ({ stripe, cart: cartStore }) => {
  const { order, charge, chargeError } = cartStore
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)
  const [cardError, setCardError] = useState(null)

  const validate = values => {
    let errors = {}

    if (!values.isSameShippingAddress) {
      if (!values.billingName) errors.billingName = 'Required'
      if (!values.billingCountry) errors.billingCountry = 'Required'
      if (!values.billingRegion) errors.billingRegion = 'Required'
      if (!values.billingCity) errors.billingCity = 'Required'
      if (!values.billingZip) errors.billingZip = 'Required'
      if (!values.billingAddress) errors.billingAddress = 'Required'
    }
    if (!values.selectedShippingMethod) {
      errors.selectedShippingMethod = 'Required'
    }
    return errors
  }

  const submit = async values => {
    cartStore.setCharge(values)
    setIsSubmittingOrder(true)
    const { token, error } = await stripe.createToken({
      name: values.billingName,
    })
    if (error) {
      cartStore.setChargeError('An error occurred with the card you provided.')
      setCardError(error.message)
      setIsSubmittingOrder(false)
      return
    }
    if (token) {
      setCardError('')
      const oid = await cartStore.payOrder(token.id)
      if (oid) {
        navigate(`/thanks/?oid=${oid}`)
        return
      }
    }
    setIsSubmittingOrder(false)
  }

  if (!cartStore.items.length) {
    if (global.window) navigate('/cart')
    return null
  }
  if (!order.id) {
    if (global.window) navigate('/checkout')
    return null
  }
  return (
    <Formik
      initialValues={charge}
      isInitialValid={!Object.keys(validate(charge)).length}
      validate={validate}
      onSubmit={submit}
      validateOnBlur={false}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Title>Payment</Title>
          <Input
            type="text"
            name="billingName"
            autoComplete="name"
            placeholder="Name on card"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.billingName}
          />
          {touched.billingName && errors.billingName && (
            <Error>{errors.billingName}</Error>
          )}
          <StripeElementWrapper>
            <CardElement style={stripeConfig.style} />
          </StripeElementWrapper>
          {cardError && <Error>{cardError}</Error>}
          <Title>Shipping Address</Title>
          <AddressCard />
          <BillingTitle>
            Billing Address
            <ToggleWrap>
              <span>Same as Shipping?</span>
              <ToggleInput
                selected={values.isSameShippingAddress}
                onChange={() =>
                  setFieldValue(
                    'isSameShippingAddress',
                    !values.isSameShippingAddress,
                  )
                }
              />
            </ToggleWrap>
          </BillingTitle>
          {!values.isSameShippingAddress && (
            <>
              <NakedInputWrap field="select" hasValue={!!values.billingCountry}>
                <CountryDropdown
                  name="billingCountry"
                  value={values.billingCountry}
                  onChange={val => setFieldValue('billingCountry', val)}
                  whitelist={checkoutConfig.whitelistCountries}
                />
              </NakedInputWrap>
              {touched.billingCountry && errors.billingCountry && (
                <Error>{errors.billingCountry}</Error>
              )}
              {(values.billingCountry ||
                (touched.billingRegion && errors.billingRegion)) && (
                <>
                  <NakedInputWrap
                    field="select"
                    hasValue={!!values.billingRegion}
                  >
                    <RegionDropdown
                      name="billingRegion"
                      value={values.billingRegion}
                      country={values.billingCountry}
                      onChange={val => setFieldValue('billingRegion', val)}
                    />
                  </NakedInputWrap>
                  {touched.billingRegion && errors.billingRegion && (
                    <Error>{errors.billingRegion}</Error>
                  )}
                </>
              )}
              <Input
                type="text"
                name="billingAddress"
                autoComplete="street-address"
                placeholder="Address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billingAddress}
              />
              {touched.billingAddress && errors.billingAddress && (
                <Error>{errors.billingAddress}</Error>
              )}
              <RowSplit split={[66, 34]}>
                <RowSplitItem>
                  <Input
                    type="text"
                    name="billingCity"
                    autoComplete="address-level2"
                    placeholder="City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.billingCity}
                  />
                  {touched.billingCity && errors.billingCity && (
                    <Error>{errors.billingCity}</Error>
                  )}
                </RowSplitItem>
                <RowSplitItem>
                  <Input
                    type="text"
                    name="billingZip"
                    autoComplete="postal-code"
                    placeholder="Zip"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.billingZip}
                  />
                  {touched.billingZip && errors.billingZip && (
                    <Error>{errors.billingZip}</Error>
                  )}
                </RowSplitItem>
              </RowSplit>
            </>
          )}
          <Title>Shipping Method</Title>
          <NakedInputWrap
            field="select"
            hasValue={!!values.selectedShippingMethod}
          >
            <Field
              component="select"
              name="selectedShippingMethod"
              onChange={handleChange}
            >
              <option value="">&mdash;</option>
              {order.shippingMethods.map((method, key) => (
                <option
                  key={`shipping-method-${key}`}
                  value={method.id}
                  selected={method.id === values.selectedShippingMethod}
                >
                  {method.description} &middot; {formatPrice(method.amount)}
                </option>
              ))}
            </Field>
          </NakedInputWrap>
          {touched.selectedShippingMethod && errors.selectedShippingMethod && (
            <Error>{errors.selectedShippingMethod}</Error>
          )}
          <CartSummary />
          {chargeError && <Error>{chargeError}</Error>}
          <SubmitButton
            onMouseDown={handleSubmit}
            disabled={!isValid || isSubmittingOrder}
          >
            Submit Order
          </SubmitButton>
          {isSubmittingOrder && <BusyMessage>Submitting Order</BusyMessage>}
        </Form>
      )}
    />
  )
}

export default inject('cart')(observer(injectStripe(PayForm)))
