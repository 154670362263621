import React from 'react'
import { inject } from 'mobx-react'
import styled from 'styled-components'
import { rem, minWidth } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import Card from 'components/card'

const { pearl } = theme.colors

const StyledCard = styled(Card)`
  &&& {
    background: ${pearl};
    font-size: ${rem(14)};
    padding: ${rem(16)};
    width: 100%;
  }

  p {
    text-align: center;
  }

  strong {
    display: block;
    font-weight: 600;
  }

  ${minWidth(320)`
    br:last-of-type {
      display: none;
    }
  `}

  ${media.small`
    &&& {
      align-items: flex-start;
    }
    p {
      text-align: left;
    }
  `}
`

export const AddressCard = ({ cart: cartStore }) => {
  const {
    shippingName: name,
    shippingAddress: address,
    shippingCity: city,
    shippingRegion: region,
    shippingZip: zip,
    shippingCountry: country,
  } = cartStore.checkout

  return (
    <StyledCard>
      <p>
        <strong>{name}</strong>
        {address}
        <br />
        {city} {region}
        {!!zip && `, ${zip}`} <br />
        {country}
      </p>
    </StyledCard>
  )
}

export default inject('cart')(AddressCard)
